import { IOnBoarding, IOnboardingDraft } from "interfaces";
import { institutionalApi } from "./index";
import { transformErrorResponse } from "../../utils";
import { KYBStatus } from "interfaces/enums";

export const onBoardingApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBrokers: builder.query<any, void>({
      query: () => ({
        url: `/kyb/broker_name_map`,
      }),
    }),
    getAllOnboardings: builder.query<
      Array<IOnBoarding>,
      {
        endpoint: string;
        live_only?: boolean;
        status?: KYBStatus;
        // order: { dir: "asc" | "desc"; field: string };
        sort_by: { dir: "asc" | "desc"; field: string };
      }
    >({
      query: (payload) => ({
        url: `/kyb/${payload.endpoint}`,
        params: { status: payload.status, sort_by: payload.sort_by?.field },
      }),
      // transformResponse: (
      //   data: Array<IOnBoarding>,
      //   _,
      //   params: { order: OrderParams },
      // ) => {
      //   const transformed = data.sort((a, b) => {
      //     const field = params.order.field;
      //     const order = params.order.dir === "desc" ? -1 : 1;

      //     //@ts-expect-error
      //     const valA = a[field];
      //     //@ts-expect-error
      //     const valB = b[field];

      //     if (typeof valA === "string" && typeof valB === "string") {
      //       return valA.localeCompare(valB) * order;
      //     }

      //     if (typeof valA === "number" && typeof valB === "number") {
      //       return (valA - valB) * order;
      //     }

      //     return 0; // Default case if types mismatch
      //   });
      //   return transformed;
      // },
      providesTags: ["Onboardings"],
      transformErrorResponse,
    }),
    getOnboardingDocument: builder.query<
      string,
      { id: string; filename: string }
    >({
      query: (params) => ({
        url: `/kyb/document/${params.id}`,
        params: { filename: params.filename },
      }),
      transformErrorResponse,
    }),
    getOnboarding: builder.query<IOnBoarding, { id: string }>({
      query: (params) => ({
        url: `/kyb`,
        params: { kyb_id: params.id },
      }),
      providesTags: (_, __, args) => [{ type: "Onboarding", id: args.id }],
      transformErrorResponse,
    }),
    updateOnboardingUnRegulated: builder.mutation<
      IOnBoarding,
      IOnboardingDraft
    >({
      query: (params) => ({
        url: `/kyb/unregulated/${params.id}`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["Onboarding"],
      transformErrorResponse,
    }),
    updateOnboardingRegulated: builder.mutation<IOnBoarding, IOnboardingDraft>({
      query: (params) => ({
        url: `/kyb/regulated/${params.id}`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["Onboarding"],
      transformErrorResponse,
    }),
    createOnboarding: builder.mutation<IOnBoarding, IOnboardingDraft>({
      query: (params) => ({
        url: `/kyb/kyb_email`,
        method: "POST",
        params: { email: params.email },
      }),
      invalidatesTags: ["Onboardings"],
      transformErrorResponse,
    }),
    changeOnboardingStatus: builder.mutation<IOnBoarding, IOnboardingDraft>({
      query: (body) => ({
        url: `/kyb/status/${body.id}`,
        method: "POST",
        params: { status: body.status },
      }),
      invalidatesTags: ["Onboardings"],
      transformErrorResponse,
    }),
    addDataKYB: builder.mutation<
      IOnboardingDraft,
      { extracted_data_map: unknown; id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/kyb/extracted_data/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Onboardings"],
      transformErrorResponse,
    }),
    addDocumentsKYB: builder.mutation<"success", { files: any; id: string }>({
      query: ({ id, ...body }) => ({
        url: `/kyb/additional_docs/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, __, params) => [
        "Onboardings",
        { type: "Onboarding", id: params.id },
      ],
      transformErrorResponse,
    }),
    getBrokers: builder.query<{ [k: string]: string }, void>({
      query: () => ({
        url: "/router/admin/client/alias",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetBrokersQuery,
  useAddDocumentsKYBMutation,
  useAddDataKYBMutation,
  useGetOnboardingQuery,
  useGetAllOnboardingsQuery,
  useGetAllBrokersQuery,
  useChangeOnboardingStatusMutation,
  useCreateOnboardingMutation,
  useLazyGetOnboardingDocumentQuery,
  useUpdateOnboardingRegulatedMutation,
  useUpdateOnboardingUnRegulatedMutation,
} = onBoardingApi;
