import { BANK_NAMES, BANKS } from "./banks";

const CLABE_LENGTH = 18;
const CLABE_WEIGHTS = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];

function computeControlDigit(clabe) {
  /*
  Compute CLABE control digit according to
    https://es.wikipedia.org/wiki/CLABE#D.C3.ADgito_control
  */
  const clabeList = clabe.split("");
  const clabeInt = clabeList.map((i) => Number(i));
  const weighted = [];

  for (let i = 0; i < CLABE_LENGTH - 1; i++) {
    weighted.push((clabeInt[i] * CLABE_WEIGHTS[i]) % 10);
  }
  const summed = weighted.reduce((curr, next) => curr + next) % 10;
  const controlDigit = (10 - summed) % 10;
  return controlDigit.toString();
}

export function validateClabe(clabe) {
  /*
  Validate CLABE according to
    https://es.wikipedia.org/wiki/CLABE#D.C3.ADgito_control
  */
  const isNumber = isANumber(clabe);
  const hasLength = clabe.length === CLABE_LENGTH;
  const bank = BANKS.findIndex(
    (b) => b.substring(2, 5) === clabe.substring(0, 3),
  );
  const hasBank = bank > -1;

  const hasControlDigit =
    clabe.substring(CLABE_LENGTH - 1) === computeControlDigit(clabe);

  console.info({ isNumber, hasLength, hasBank, hasControlDigit });

  return isNumber && hasLength && hasBank && hasControlDigit;
}

export function getBankName(clabe) {
  /*
  Regresa el nombre del banco basado en los primeros 3 digitos
    https://es.wikipedia.org/wiki/CLABE#D.C3.ADgito_control
  */
  const code = clabe.substring(0, 3);
  const bankName = BANK_NAMES[BANKS[code]];

  if (bankName === undefined)
    throw Error("Ningún banco tiene este código " + code);
  return bankName;
}
// will return true only if characters in a string are digits
function isANumber(str) {
  return !/\D/.test(str);
}
