import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import {
  Button,
  ControlledCreatableSelectGray,
  ControlledInput,
  ControlledSelectGray,
  Grid,
  Input,
  errorSnack,
} from "components/Shared";
import { CreateDestinationBankAccountSnack } from "components/Shared/Snacks";
import { enqueueSnackbar } from "notistack";
import { Tooltip } from "react-tooltip";
import { IRouterCreateBankAcc, IRouterSubAccount } from "interfaces/router";
import {
  useCreateBankAccountRouterMutation,
  useGetMexicoBankAccountsQuery,
} from "api/institutional/router_api";
import {
  RouterBankAccountTypes,
  ReapTransfers,
  RouterTransferMethods,
  RouterSupportedDOCountry,
} from "interfaces/enums";
import { getBankOptions } from "utils/getBankOptions";

import { useDialog } from "react-dialog-async";
import ConfirmAddress from "../ConfirmAddress";

import {
  useFindTransferMethodsByFiatAndCountry,
  useGetFiatByCountry,
} from "hooks/useCurrencyForm";
import { valueLabelMapper } from "utils";
import { IUser } from "interfaces";
import { validateClabe } from "utils/clabe";
import { ChangeEvent, useEffect } from "react";

const NetworkIdName = ({
  transfer_method,
}: {
  transfer_method: RouterTransferMethods;
}) => {
  const { t } = useTranslation();
  if (
    transfer_method === RouterTransferMethods.HK_CHATS ||
    transfer_method === RouterTransferMethods.SWIFT
  ) {
    return <>{t("Swift Code")}</>;
  }
  if (transfer_method === RouterTransferMethods.HK_FPS) {
    return <>{t("Bank Code")}</>;
  }
  return <></>;
};

const useGetMexico = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<IRouterCreateBankAcc>();

  return [
    {
      label: t("RFC"),
      component: (
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="rfc"
          render={(renderProps) => (
            <ControlledInput placeholder={t("RFC")} renderProps={renderProps} />
          )}
        />
      ),
    },
    {
      label: t("CLABE"),
      component: (
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (a) => validateClabe(a),
          }}
          name="clabe"
          render={(renderProps) => (
            <ControlledInput
              placeholder={t("CLABE")}
              renderProps={renderProps}
            />
          )}
        />
      ),
    },
  ];
};

const useGetAddress = () => {
  const { control, watch, setValue } = useFormContext<IRouterCreateBankAcc>();

  const transfer_method = watch("transfer_method");

  const maxLengthMemo =
    transfer_method === RouterTransferMethods.US_ACH
      ? 10
      : transfer_method === RouterTransferMethods.US_WIRE
        ? 140
        : undefined;

  const { t } = useTranslation();

  useEffect(() => {
    setValue("memo", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfer_method]);

  return [
    {
      label: t("Street 1"),
      component: (
        <Controller
          control={control}
          rules={{ required: true }}
          name="street_1"
          render={(renderProps) => (
            <ControlledInput renderProps={renderProps} />
          )}
        />
      ),
    },
    {
      label: t("Street 2"),
      component: (
        <Controller
          control={control}
          name="street_2"
          render={(renderProps) => (
            <ControlledInput renderProps={renderProps} />
          )}
        />
      ),
    },
    {
      label: t("City"),
      component: (
        <Controller
          control={control}
          rules={{ required: true }}
          name="city"
          render={(renderProps) => (
            <ControlledInput renderProps={renderProps} />
          )}
        />
      ),
    },
    {
      label: t("State"),
      component: (
        <Controller
          control={control}
          rules={{ required: true }}
          name="state"
          render={(renderProps) => (
            <ControlledInput renderProps={renderProps} />
          )}
        />
      ),
    },
    {
      label: t("Postal Code"),
      component: (
        <Controller
          control={control}
          rules={{ required: true }}
          name="postal_code"
          render={(renderProps) => (
            <ControlledInput renderProps={renderProps} />
          )}
        />
      ),
    },
    {
      hidden: ![
        RouterTransferMethods.US_WIRE,
        RouterTransferMethods.US_ACH,
      ].includes(transfer_method),
      label: t("For Further Credit To / Memo"),
      component: (
        <Controller
          name={`memo`}
          control={control}
          rules={{
            max: maxLengthMemo,
            maxLength: maxLengthMemo,
          }}
          render={({ field }) => (
            <Input
              maxLength={maxLengthMemo}
              placeholder={t("For Further Credit To / Memo")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setValue("memo", e.currentTarget.value)
              }
              value={field.value}
            />
          )}
        />
      ),
    },
  ];
};

const useReapFields = () => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<IRouterCreateBankAcc>();

  const transfer_method = watch("transfer_method");

  if (!ReapTransfers.includes(transfer_method)) return [];

  return [
    {
      label: t("Account Number Type"),
      component: (
        <Controller
          name="account_number_type"
          rules={{ required: true }}
          control={control}
          render={(renderProps) => (
            <ControlledSelectGray
              renderProps={renderProps}
              isClearable={true}
              options={
                [
                  RouterTransferMethods.HK_CHATS,
                  RouterTransferMethods.HK_FPS,
                ].includes(transfer_method)
                  ? ["ACCOUNT_NUMBER"]
                  : ["IBAN", "ACCOUNT_NUMBER"]
              }
            />
          )}
        />
      ),
    },
    {
      label: <NetworkIdName transfer_method={transfer_method} />,
      component: (
        <Controller
          control={control}
          rules={{ required: true }}
          name="bank_network_id"
          render={(renderProps) => (
            <ControlledInput renderProps={renderProps} />
          )}
        />
      ),
    },
  ];
};

const useNumberFields = () => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<IRouterCreateBankAcc>();

  const transfer_method = watch("transfer_method");

  const country = watch("country");

  if (country === RouterSupportedDOCountry.MX || !transfer_method) {
    return [];
  }

  if (ReapTransfers.includes(transfer_method))
    return [
      {
        label: t("Account Number"),
        component: (
          <Controller
            control={control}
            name="account_number"
            render={(renderProps) => (
              <ControlledInput
                placeholder={t("Account Number")}
                renderProps={renderProps}
              />
            )}
          />
        ),
      },
    ];

  return [
    {
      label: t("Account Type"),
      component: (
        <Controller
          name="account_type"
          rules={{
            required: !ReapTransfers.includes(transfer_method),
          }}
          control={control}
          render={(renderProps) => (
            <ControlledSelectGray
              renderProps={renderProps}
              options={[
                RouterBankAccountTypes.CHECKING,
                RouterBankAccountTypes.SAVINGS,
              ]}
            />
          )}
        />
      ),
    },

    {
      label: t("Account Number"),
      component: (
        <Controller
          control={control}
          name="account_number"
          render={(renderProps) => (
            <ControlledInput
              placeholder={t("Account Number")}
              renderProps={renderProps}
            />
          )}
        />
      ),
    },
    {
      label: t("Routing Number"),
      component: (
        <Controller
          control={control}
          name="routing_number"
          render={(renderProps) => (
            <ControlledInput
              placeholder={t("Routing Number")}
              renderProps={renderProps}
            />
          )}
        />
      ),
    },
  ];
};

export const Account = ({
  handleClose,
  subAccounts,
  user,
}: {
  subAccounts: Array<IRouterSubAccount>;
  handleClose: () => void;
  user: IUser;
}) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { isValid },
    reset,
    setValue,
    resetField,
    watch,
    setError,
    setFocus,
    handleSubmit,
  } = useFormContext<IRouterCreateBankAcc>();

  const country = watch("country");

  const fiat = watch("fiat");

  const { data: mexicoBankAccounts, isLoading: isLoadingMexicoBankAccounts } =
    useGetMexicoBankAccountsQuery(undefined, {
      skip: country !== RouterSupportedDOCountry.MX,
    });

  const bankNameOptions = valueLabelMapper(getBankOptions(country));

  const { destinations } = useGetFiatByCountry({ country });

  const confirmationDialog = useDialog(ConfirmAddress);

  const { transferMethods } = useFindTransferMethodsByFiatAndCountry({
    fiat,
    country,
  });

  const [addBankAccount, { isLoading }] = useCreateBankAccountRouterMutation();

  const handleAddBank = async (values: IRouterCreateBankAcc) => {
    await addBankAccount(values).unwrap();
    reset();
    enqueueSnackbar(<CreateDestinationBankAccountSnack />);
    handleClose();
  };

  const submitAddBankAccount = async (values: IRouterCreateBankAcc) => {
    try {
      await handleAddBank(values);
    } catch (e: unknown) {
      let error = e as { error: string; correction?: any };
      if (typeof e === "string") {
        if ((e as string).startsWith("routing_number")) {
          setError("routing_number", {
            message: t("Does not support wire transfer"),
          });
          setFocus("routing_number");
        }
        enqueueSnackbar(e as string, errorSnack);
      } else {
        if (error?.error) {
          if (error.correction) {
            const response = await confirmationDialog.show({
              title: t("Error detected in address"),
              message: t("Corrected address"),
              values: error.correction,
            });
            if (response) {
              setValue("city", error.correction.city);
              setValue("postal_code", error.correction.postal_code);
              setValue("state", error.correction.state);
              setValue("street_1", error.correction.street_1);
              setValue("street_2", error.correction.street_2);
              submitAddBankAccount({ ...values, ...error.correction });
            }
          } else {
            enqueueSnackbar(error.error, errorSnack);
          }
        } else {
          enqueueSnackbar(
            t("An error occurred. Please notifiy Ridian."),
            errorSnack,
          );
        }
      }
    }
  };

  const mexico = useGetMexico();

  const address = useGetAddress();

  const reapFields = useReapFields();

  const numberFields = useNumberFields();

  const countryOptions = [
    { label: t("US"), value: RouterSupportedDOCountry.US },
    { label: t("MX"), value: RouterSupportedDOCountry.MX },
    { label: t("AU"), value: RouterSupportedDOCountry.AU },
    { label: t("IT"), value: RouterSupportedDOCountry.IT },
    { label: t("KR"), value: RouterSupportedDOCountry.KR },
    { label: t("HK"), value: RouterSupportedDOCountry.HK },
    { label: t("CN"), value: RouterSupportedDOCountry.CN },
    { label: t("CH"), value: RouterSupportedDOCountry.CH },
    { label: t("JP"), value: RouterSupportedDOCountry.JP },
    { label: t("NZ"), value: RouterSupportedDOCountry.NZ },
    { label: t("SG"), value: RouterSupportedDOCountry.SG },
  ];

  return (
    <form
      onSubmit={handleSubmit(submitAddBankAccount)}
      className="gap-5 flex flex-col"
    >
      <Grid>
        {[
          {
            hidden: !user.isRouterBroker,
            label: "Client",
            component: (
              <Controller
                control={control}
                name="sub_account_id"
                render={(renderProps) => (
                  <ControlledSelectGray
                    isClearable={true}
                    options={subAccounts.map((s) => ({
                      label: s.client_name,
                      value: s.sub_account_id,
                    }))}
                    renderProps={renderProps}
                  />
                )}
              />
            ),
          },
          {
            label: t("Destination Country"),
            component: (
              <Controller
                control={control}
                rules={{
                  required: true,
                  onChange: () => {
                    resetField("bank_name");
                    resetField("fiat");
                    resetField("routing_number");
                    resetField("account_number");
                    resetField("bank_network_id");
                    resetField("transfer_method");
                    resetField("account_number_type");
                  },
                }}
                name="country"
                render={(renderProps) => (
                  <ControlledSelectGray
                    renderProps={renderProps}
                    options={countryOptions}
                  />
                )}
              />
            ),
          },
          {
            label: t("Alias"),
            component: (
              <Controller
                control={control}
                rules={{ required: true }}
                name="alias"
                render={(renderProps) => (
                  <ControlledInput
                    placeholder={t("Alias")}
                    renderProps={renderProps}
                  />
                )}
              />
            ),
          },
          {
            label: t("Recipient's Name"),
            component: (
              <Controller
                control={control}
                name="account_name"
                rules={{ required: true }}
                render={(renderProps) => (
                  <ControlledInput
                    placeholder={t("Enter the full name of the recipient")}
                    renderProps={renderProps}
                  />
                )}
              />
            ),
          },
          {
            label: t("Bank Name"),
            component: (
              <Controller
                name="bank_name"
                rules={{ required: true }}
                control={control}
                render={(renderProps) =>
                  country === RouterSupportedDOCountry.MX ? (
                    <ControlledSelectGray
                      renderProps={renderProps}
                      isLoading={isLoadingMexicoBankAccounts}
                      options={
                        country === RouterSupportedDOCountry.MX
                          ? valueLabelMapper(mexicoBankAccounts || [])
                          : bankNameOptions
                      }
                    />
                  ) : (
                    <ControlledCreatableSelectGray
                      options={bankNameOptions}
                      renderProps={renderProps}
                      formatCreateLabel={(prop: any) => `${t("Bank")}: ${prop}`}
                    />
                  )
                }
              />
            ),
          },
          {
            label: t("FIAT"),
            component: (
              <Controller
                name="fiat"
                rules={{
                  required: true,
                  onChange: () => {
                    resetField("transfer_method");
                  },
                }}
                control={control}
                render={(renderProps) => (
                  <ControlledSelectGray
                    renderProps={renderProps}
                    options={destinations}
                  />
                )}
              />
            ),
          },
          {
            hidden: !watch("fiat"),
            label: t("Transfer Method"),
            component: (
              <Controller
                name="transfer_method"
                rules={{
                  required: true,
                }}
                control={control}
                render={(renderProps) => (
                  <ControlledSelectGray
                    renderProps={renderProps}
                    options={transferMethods}
                  />
                )}
              />
            ),
          },
        ]
          .concat(numberFields as any)
          .concat(reapFields as any)
          .concat(country === "MX" ? mexico : address)}
      </Grid>

      <div className="flex gap-2">
        <Button
          className="bg-blue hover:bg-blue-dark100 rounded-full text-white text-4 py-2 px-6 items-center"
          type="submit"
          disabled={isLoading || !isValid}
        >
          {isLoading
            ? t("Adding Destination Account")
            : t("Add Destination Account")}
        </Button>
        <Tooltip style={{ maxWidth: "20rem" }} id="validation-message" />
      </div>
    </form>
  );
};
