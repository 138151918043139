import { Button } from "@mui/material";
import { useAddDocumentsKYBMutation } from "api/institutional/onboarding";
import { UploadOrShow } from "./UploadOrShow";
import { useUploader } from "hooks/useUpload";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";

import { FilePreview } from "./FileItem";
import { useTranslation } from "react-i18next";
import useErrorListener from "hooks/useErrorListener";
import { IOnBoarding } from "interfaces";
import { errorSnack, successSnack } from "components/Shared";

interface File {
  filename: string;
  category: string;
  locked?: boolean;
}
const AddFile = ({
  onboardingId,
  files,
  removeFile,
  category,
  addFile,
  label,
}: {
  removeFile: (file: File) => void;
  addFile: (file: File) => void;
  category: string;
  onboardingId: string;
  files: Array<File>;
  label: string;
}) => {
  const { upload, isLoading: isUploading } = useUploader();

  const doDrop = async (newFiles: [any], category: string) => {
    const filename = await upload(
      `/kyb/document/${onboardingId}`,
      newFiles,
      category,
    );
    addFile({ filename, category });
  };

  const hasFile = files.find((f) => f.category === category);

  if (hasFile) {
    return (
      <FilePreview
        filename={hasFile.filename}
        handleOnDelete={() => removeFile(hasFile)}
        locked={hasFile.locked}
      />
    );
  }

  return (
    <UploadOrShow
      category={category}
      doDrop={doDrop}
      isUploading={isUploading}
      label={label}
      multiUpload={false}
    />
  );
};

const categories = [
  "bank_statement_1",
  "bank_statement_2",
  "invoice_1",
  "invoice_2",
  "proving_activity_1",
  "proving_activity_2",
];

export const AddExtraDocuments = ({
  onboarding,
}: {
  onboarding: IOnBoarding;
}) => {
  const { t } = useTranslation();
  const [mutateAddDocument, { error, isLoading }] =
    useAddDocumentsKYBMutation();

  useErrorListener({ error });

  const [files, setFiles] = useState<Array<File>>([]);

  useEffect(() => {
    if (onboarding?.files?.length) {
      const f: Array<File> = [];
      categories.forEach((cat) => {
        const e = onboarding?.files.find((c) => c.category === cat);
        if (e) {
          f.push({ ...e, locked: true });
        }
      });
      setFiles(f);
    }
  }, [onboarding?.files]);

  const handleMutateDocument = async () => {
    try {
      await mutateAddDocument({
        id: onboarding.id,
        files,
      }).unwrap();
      enqueueSnackbar(t("Files saved"), successSnack);
    } catch (e) {
      enqueueSnackbar(t("Error saving files"), errorSnack);
    }
  };

  const addFile = (file: File) => {
    setFiles([...files, file]);
  };

  const removeFile = (file: File) => {
    const fff = files.filter(
      (f) => file.category === f.category && file.filename === f.filename,
    );
    console.log({ fff });
    setFiles(files);
  };

  return (
    <div className="flex flex-col gap-4">
      <AddFile
        onboardingId={onboarding.id}
        files={files}
        addFile={addFile}
        removeFile={removeFile}
        category={"bank_statement_1"}
        label={t("Bank statement")}
      />

      <AddFile
        onboardingId={onboarding.id}
        files={files}
        addFile={addFile}
        removeFile={removeFile}
        category={"bank_statement_2"}
        label={t("Extra Bank statement")}
      />

      <AddFile
        onboardingId={onboarding.id}
        files={files}
        addFile={addFile}
        removeFile={removeFile}
        category={"invoice_1"}
        label={t("Invoice")}
      />

      <AddFile
        onboardingId={onboarding.id}
        files={files}
        addFile={addFile}
        removeFile={removeFile}
        category={"invoice_2"}
        label={t("Extra Invoice")}
      />

      <AddFile
        onboardingId={onboarding.id}
        files={files}
        addFile={addFile}
        removeFile={removeFile}
        category={"proving_activity_1"}
        label={t("Document Proving Activity")}
      />

      <AddFile
        onboardingId={onboarding.id}
        files={files}
        addFile={addFile}
        removeFile={removeFile}
        category={"proving_activity_2"}
        label={t("Extra Document Proving Activity")}
      />
      <Button
        className="w-full"
        disabled={isLoading}
        variant="contained"
        onClick={handleMutateDocument}
      >
        {t("Save")}
      </Button>
    </div>
  );
};
