import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

export const MobileLangSelector = () => {
  const { i18n } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.currentTarget.value);
  };
  return (
    <select
      className="form p-2 bg-white w-full block underline"
      onChange={handleChange}
      defaultValue={i18n.language}
    >
      <option value="es">ES</option>
      <option value="en">EN</option>
    </select>
  );
};

export const LangSelector = () => {
  const { i18n } = useTranslation();

  const handleChange = (lang: any) => {
    i18n.changeLanguage(lang);
  };

  const selected = (bool: boolean) => {
    return bool
      ? "bg-black text-white p-1"
      : "bg-white p-1 text-black cursor-pointer";
  };

  return (
    <div className="flex items-center w-full">
      <div
        onClick={() => handleChange("en")}
        className={`flex-1 text-center text-3 ${selected(
          i18n.language === "en",
        )}`}
      >
        EN
      </div>

      <div
        onClick={() => handleChange("es")}
        className={`flex-1 text-center text-3 ${selected(
          i18n.language === "es",
        )}`}
      >
        ES
      </div>
    </div>
  );
};

export default LangSelector;
