import { NavSidebarButton } from "../NavSideBarButton";
import { Link, useLocation } from "react-router-dom";
import { useGetUserQuery } from "api/institutional/user";
import { Locations, Roles } from "interfaces/enums";

import { ISidebarMenuOptions } from "interfaces";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export const MenuItem = ({
  option,
  isBottomBar,
  handleClose,
}: {
  isBottomBar?: boolean;
  handleClose: any;
  option: ISidebarMenuOptions;
}) => {
  const pathname = useLocation().pathname as Locations;

  const { t } = useTranslation();
  const { data: user } = useGetUserQuery({});

  /** Hide admin menus */
  if (option.role && option.role === Roles.admin && !user?.is_admin) {
    return null;
  }

  if (option.role && option.role === Roles.admin && user?.is_admin) {
    if (option.isSeparator) {
      return (
        <NavSidebarButton
          label="Admin"
          selected={pathname.startsWith("/admin")}
          isBottomBar={isBottomBar}
          icon={option.icon}
          to={option.route}
          handleClose={handleClose}
        />
      );
    }
    if (option.isChild) {
      if (
        option.showTo.length &&
        !user?.flags.some((flag) => option.showTo.includes(flag))
      ) {
        return null;
      }
      return (
        <div className="px-8">
          •{" "}
          <Link
            className={classNames({
              underline: pathname === option.route,
              "px-2": true,
            })}
            to={option.route}
          >
            {t(option.label ?? "")}
          </Link>
        </div>
      );
    }
    return (
      <NavSidebarButton
        selected={pathname === option.route}
        isBottomBar={isBottomBar}
        icon={option.icon}
        to={option.route}
        handleClose={handleClose}
      />
    );
  }

  /** Hide mobile menus */
  if (option.hideOnMobile && isBottomBar) {
    return null;
  }

  /** Hide based on flags menus */
  if (!user?.flags.some((flag) => option.showTo.includes(flag))) {
    return null;
  }

  /** Hide nav bar menus */
  if (option.hideOnNavBar) {
    return null;
  }

  return (
    <NavSidebarButton
      selected={pathname === option.route}
      isBottomBar={isBottomBar}
      icon={option.icon}
      to={option.route}
      handleClose={handleClose}
    />
  );
};
