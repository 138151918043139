import { Link } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import { NavSidebarIconMenu } from "../NavSidebarIconMenu";
import LangSelector from "../LangSelector";

import { ReactComponent as LogoutIcon } from "assets/svg/logout-icon.svg";
import { ReactComponent as RLogo } from "assets/svg/R.svg";
import { Locations } from "interfaces/enums";
import { useTranslation } from "react-i18next";
import { Policy } from "@mui/icons-material";

const SideBar = () => {
  const { logout } = useAuth();

  const { t } = useTranslation();

  return (
    <div className="hidden md:flex md:flex-col fixed align-top md:w-[212px] h-full bg-white gap-4 p-4 shadow-md">
      <div className="flex">
        <Link to={Locations["/"]} className="">
          <RLogo />
        </Link>
      </div>

      <div className="flex-1 items-center overflow-auto">
        {/* NavSideBarIconMenu */}
        <NavSidebarIconMenu />
      </div>

      <div className="flex flex-col gap-6">
        <div>
          <Link
            to={Locations["/terms-and-conditions"]}
            className="text-3 text-gray-dark500 flex gap-1 items-center underline"
          >
            <Policy style={{ height: "16px" }} />
            {t("Terms and Conditions")}
          </Link>
        </div>

        <div className="flex items-center justify-between">
          <LangSelector />
        </div>

        <div className="flex w-full">
          <button
            onClick={() => logout()}
            className="flex justify-between items-center text-black cursor-pointer w-full hover:bg-gray-light100"
          >
            <span className="text-4">{t("Logout")}</span>
            <LogoutIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
